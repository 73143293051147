<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <div class="card-header-actions" style="text-align: right; padding-top: 2px;">
            <CButton color="info" size="sm" @click="showDashboard()" style="margin-right: 5px;">Ver Dashboard</CButton>
          </div>
          <h3 style="margin-bottom: 0px;">{{challenge.name}}</h3>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12">
            <div class="card-header-actions" style="text-align: right; height: 10px;">
              {{(challenge.type == 'sales' ? 'Venta Mínima' : (challenge.type == 'customers' ? 'Clientes Mínimos' : challenge.type == 'boxes' ? 'Cajas Mínimo' : ''))}}
              <span v-if="challenge.type == 'boxes'"><br />{{(challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : 'Individual Por Cada Caja Vendida')}}</span>
            </div>
            <br />
            <ul class="nav nav-tabs justify-content-begin">
              <li @click="changeTab('info')" class="nav-item">
                <a class="nav-link" :class="tab == 'info' ? 'active' : ''" aria-current="page">Información</a>
              </li>
              <li @click="changeTab('rules')" class="nav-item">
                <a class="nav-link" :class="tab == 'rules' ? 'active' : ''">Dinámica</a>
              </li>
              <li v-if="challenge.type != 'video'" @click="changeTab('products')" class="nav-item">
                <a class="nav-link" :class="tab == 'products' ? 'active' : ''">Productos</a>
              </li>
              <li @click="changeTab('sales')" class="nav-item">
                <a class="nav-link" :class="tab == 'sales' ? 'active' : ''">Ventas</a>
              </li>
              <li v-if="challenge.type == 'video'" @click="changeTab('quest')" class="nav-item">
                <a class="nav-link" :class="tab == 'quest' ? 'active' : ''">Cuestionario</a>
              </li>
              <li @click="changeTab('awards')" class="nav-item">
                <a class="nav-link" :class="tab == 'awards' ? 'active' : ''">Premios</a>
              </li>
              <!--
              <li @click="changeTab('cedis')" class="nav-item">
                <a class="nav-link" :class="tab == 'cedis' ? 'active' : ''">Cedis</a>
              </li>
              -->
              <li @click="changeTab('entries')" class="nav-item">
                <a class="nav-link" :class="tab == 'entries' ? 'active' : ''">Participantes</a>
              </li>
              <!--
              <li @click="changeTab('customers')" class="nav-item">
                <a class="nav-link" :class="tab == 'customers' ? 'active' : ''">Clientes</a>
              </li>
              -->
            </ul>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CCard v-show="tab == 'info'">
              <CCardBody>
                <div style="float: right; text-align: right;" v-show="challenge.type == 'video'">
                  <div v-for="(video, index) in challenge.videos"><a :href="'https://www.youtube.com/watch?v='+video.youtube_reference" target="_blank">https://www.youtube.com/watch?v={{video.youtube_reference}}</a></div>
                  <br />
                </div>
                <CRow>
                  <CCol md="12">
                    <CRow>
                      <CCol md="12">
                        <b>Tipo: </b>{{challenge.type == 'video' ? 'Capacitación' : (challenge.type == 'sales' ? 'Venta Mínima' : (challenge.type == 'customers' ? 'Clientes Mínimos' : challenge.type == 'boxes' ? 'Cajas Mínimo' : '-'))}}<br /><br />
                      </CCol>
                    </CRow> 
                    <CRow>
                      <CCol md="12" v-if="challenge.type == 'boxes' && challenge.mechanic != 'normal'">
                        <b>Mécanica: </b>{{challenge.mechanic == 'total' ? 'Numero Total de Cajas Vendidas' : (challenge.mechanic == 'ranges' ? 'Rangos de Cajas Vendidas' : (challenge.mechanic == 'individual' ? 'Individual Por Cada Caja Vendida' : ''))}}<br /><br />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <b>Nombre: </b>{{challenge.name}}
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <b>Descripción: </b>{{challenge.description}}<br /><br />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <b>Fecha de Inicio: </b>{{challenge.start_date | date}}
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="12">
                        <b>Fecha de Termino: </b>{{challenge.end_date | date}}
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'products'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeProducts
                      :items="products"
                      :challenge="challenge"
                      :fields="product_fields"
                      hover
                      striped
                      border
                      small
                      fixed
                      caption="Productos Participantes"
                      @refresh="refreshProducts"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'sales' && challenge.type != 'video'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeSales
                      ref="refChallengeSales"
                      :fields="sales_fields"
                      hover
                      striped
                      border
                      small
                      fixed
                      caption="Ventas Participantes"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'quest' && challenge.type == 'video'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeQuest
                      :items="questions"
                      :challenge="challenge"
                      hover
                      striped
                      border
                      small
                      fixed
                      caption="Preguntas"
                      @refresh="refreshQuestions"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'cedis' && challenge.type == 'video'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    CEDIS
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'entries'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeEntries
                      :items="entries"
                      :challenge="challenge"
                      :fields="fields"
                      hover
                      striped
                      border
                      small
                      fixed
                      caption="Participantes"
                      @refresh="refreshEntries"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <ChallengeAvailable
                      :items="availables"
                      :challenge="challenge"
                      hover
                      striped
                      border
                      small
                      fixed
                      caption="¿Quienes pueden participar?"
                      @refresh="refreshAvailables"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'customers'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    CLIENTES
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'rules'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeRules ref="refChallengeRules" @add="storeProcess" @remove="refreshProcess" @update="updateRules"/>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <ChallengeManagerRules ref="refChallengeManagerRules"@update="updateManagerRules"/>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard v-show="tab == 'awards'">
              <CCardBody>
                <CRow>
                  <CCol md="12">
                    <ChallengeAwards ref="refChallengeAwards" @update="updateAwards"/>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12">
                    <ChallengeManagerAwards ref="refChallengeManagerAwards" @update="updateManagerAwards"/>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    
    <ChallengeVideoModal ref="challengeVideoModal" @store="storeProcess"></ChallengeVideoModal>
  </div>
</template>
<script>

import store from '../../store'
import challenges_ws from '../../services/challenges';
import router from '../../router/index'

import ChallengeVideoModal from '../../components/challenges/ChallengeVideoModal.vue'
import ChallengeQuest from '../../components/challenges/ChallengeQuest.vue'
import ChallengeRules from '../../components/challenges/ChallengeRules.vue'
import ChallengeManagerRules from '../../components/challenges/ChallengeManagerRules.vue'
import ChallengeAwards from '../../components/challenges/ChallengeAwards.vue'
import ChallengeManagerAwards from '../../components/challenges/ChallengeManagerAwards.vue'
import ChallengeEntries from '../../components/challenges/ChallengeEntries.vue'
import ChallengeProducts from '../../components/challenges/ChallengeProducts.vue'
import ChallengeSales from '../../components/challenges/ChallengeSales.vue'
import ChallengeAvailable from '../../components/challenges/ChallengeAvailable.vue'

export default {
  name: 'ChallengeView',
  components: {
    ChallengeVideoModal,
    ChallengeQuest,
    ChallengeRules,
    ChallengeManagerRules,
    ChallengeAwards,
    ChallengeManagerAwards,
    ChallengeEntries,
    ChallengeAvailable,
    ChallengeProducts,
    ChallengeSales
  },
  data () {
    return {
      tab: 'info',
      fields: [],
      product_fields: [],
      sales_fields: [],
      challenge_id: 0,
      challenge: {
        'type': 'boxes'
      },
      questions: [],
      entries: [],
      products: [],
      availables: [],
      ranges: [],
      awards: [],
      manager_awards: [],
      has_files: false
    }
  },
  mounted: async function() {
    this.loading();

    let challenge_id = await this.$route.params.challenge_id;
    this.challenge_id = challenge_id;

    let response = await challenges_ws.show(this.challenge_id); 

    if(response.type == "success"){
      this.challenge = response.data;
      this.awards = this.challenge.awards;
      this.manager_awards = this.challenge.manager_awards;
      this.ranges = this.challenge.ranges;

      this.refreshChallenge();
    }

    let response2 = await challenges_ws.getQuestions(this.challenge_id);

    if(response2.type == "success"){
      this.questions = response2.data;

      this.fields = [
        {key: 'supplier_name', label: 'Nombre'},
        {key: 'date', label: 'Fecha'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ];

      this.questions.forEach(async (question) => {
        if(question.type == "file"){
          this.fields = [
            {key: 'supplier_name', label: 'Nombre'},
            {key: 'files', label: 'Archivos'},
            {key: 'date', label: 'Fecha'},
            {key: 'paid', label: 'Pagado'},
            {key: 'actions', label: 'Acciones', class: 'actions' },
          ]
        }
      });
    }

    let response3 = await challenges_ws.getEntries(this.challenge_id);

    if(response3.type == "success"){
      this.entries = response3.data;
    }

    let response4 = await challenges_ws.getAvailables(this.challenge_id);

    if(response4.type == "success"){
      this.availables = response4.data;
    }

    let response5 = await challenges_ws.getProducts(this.challenge_id);

    if(response5.type == "success"){
      this.product_fields = [
        {key: 'product_code', label: 'Código'},
        {key: 'product_name', label: 'Nombre'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ];

      this.products = response5.data;
    }

    this.sales_fields = [
        {key: 'date', label: 'Fecha'},
        {key: 'product', label: 'Producto'},
        {key: 'sname', label: 'Vendedor - Cliente'},
        {key: 'boxes', label: 'Cajas'},
        {key: 'total', label: 'Monto'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
    ];

    this.loaded();
  },
  methods: {
    storeModal () {
      this.$refs.challengeVideoModal.storeModal(this.challenge_id);
    },
    async refreshChallenge () {
      await this.$refs.refChallengeAwards.loadChallenge(this.challenge, this.awards);
      await this.$refs.refChallengeManagerAwards.loadChallenge(this.challenge, this.manager_awards);
      await this.$refs.refChallengeRules.loadChallenge(this.challenge);
      await this.$refs.refChallengeManagerRules.loadChallenge(this.challenge);
      await this.$refs.refChallengeSales.loadChallenge(this.challenge);
    },
    async storeProcess (data) {
      this.loading();
      let response = await challenges_ws.addVideo(data); 

      if(response.type == "success"){
        this.challenge = response.data;
      }
      this.loaded();
    },
    async refreshProcess (data) {
      this.challenge = data;
    },
    async updateRules (data) {
      this.loading();
      let response = await challenges_ws.updateRules(data); 

      if(response.type == "success"){
        this.challenge = data.challenge;
        this.awards = this.challenge.awards;
        this.ranges = this.challenge.ranges;

        this.refreshChallenge();
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateAwards (data) {
      this.loading();
      let response = await challenges_ws.updateAwards(data); 

      if(response.type == "success"){
        this.challenge = response.data;
        this.awards = this.challenge.awards;
        this.ranges = this.challenge.ranges;

        this.refreshChallenge();

        this.loaded();
      }

      this.showToast(response.type, response.message);
      
    },
    async updateManagerRules (data) {
      this.loading();
      let response = await challenges_ws.updateManagerRules(data); 

      if(response.type == "success"){
        this.challenge = data.challenge;
        this.awards = this.challenge.awards;
        this.ranges = this.challenge.ranges;

        this.refreshChallenge();
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateManagerAwards (data) {
      this.loading();
      let response = await challenges_ws.updateManagerAwards(data); 

      if(response.type == "success"){
        this.challenge = response.data;
        this.manager_awards = this.challenge.manager_awards;
        this.ranges = this.challenge.ranges;

        this.refreshChallenge();

        this.loaded();
      }

      this.showToast(response.type, response.message);
      
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;

        if(this.challenge.type == "video" && tab == 'info'){
          if(!this.player){
            let _this = this;
            setTimeout(function(){
              _this.player = YouTubePlayer('video-player');
              _this.player.loadVideoById(_this.challenge.youtube);
            }, 1000);
          }
        }
      }
    },
    showDashboard () {
      router.push({path: '/challenges/'+this.challenge.id+'/dashboard'});
    },
    async refreshQuestions (items) {
      this.loading();
      this.questions = await items;
      this.loaded();
    },
    async refreshEntries (items) {
      this.loading();
      this.entries = await items;
      this.loaded();
    },
    async refreshProducts (items) {
      this.loading();
      this.products = await items;
      this.loaded();
    },
    async refreshAvailables (items) {
      this.loading();
      this.availables = await items;
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    }
  }
}
</script>
